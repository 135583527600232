<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error !== null" color="danger" class="mb-3">
      {{ error }}
    </CAlert>

    <h5>{{ $t("role.orders.manage.incident.batchModal.sureAlert") }}</h5>

    <h5>{{ $t("role.orders.manage.incident.batchModal.elements") }}</h5>
    <ul class="mb-2">
      <li v-for="item of items" :key="item.id">
        <span>{{ item.label }}</span>
      </li>
    </ul>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close">{{
        $t("role.orders.manage.incident.modal.btnCancel")
      }}</CButton>
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t("role.orders.manage.incident.modal.btnConfirm") }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../../helpers/EventBus";
import { getOptionLabel } from "../../../../helpers/common";
import { getIncidentBatchActionOptions } from "../../../../helpers/options";

export default {
  name: "OrdersIncidentBatchActionModal",

  data() {
    return {
      loading: false,
      error: null,
      message: "",
    };
  },

  watch: {
    show() {
      this.reset();
    },
  },

  computed: {
    title() {
      return (
        getOptionLabel(
          this.action,
          getIncidentBatchActionOptions(this.$i18n)
        ) || ""
      );
    },

    show() {
      return this.$store.state.incidents.batch.show;
    },

    action() {
      return this.$store.state.incidents.batch.action;
    },

    items() {
      const { details } = this.$store.state.incidents.batch;
      const lang = this.$i18n.locale;
      return details.map((item) => {
        const productName = get(
          item,
          `ppm.lot.productproducer.translations.${lang}.name`
        );
        const variantName = get(item, `ppm.lot.translations.${lang}.name`);
        const label = `${productName} - ${variantName}`;
        return { id: item.id, label: label };
      });
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
      this.message = "";
    },

    close() {
      this.$store.commit("incidents/batchHide");
    },

    onSubmit() {
      const { action, details } = this.$store.state.incidents.batch;

      const payload = {
        action: action,
        details: details.map((item) => item.id),
      };

      this.error = null;
      this.loading = true;
      this.$store
        .dispatch("incidents/batchAction", payload)
        .then(() => {
          this.$store.commit("incidents/resetState");
          EventBus.$emit("orders:refresh");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
