<template>
  <div class="ui-type-heading">
    <OrdersCount variant="bullet" :color="getStautsColor()" />
  </div>
</template>

<script>
import get from "lodash/get";
import { incidentProgressMap } from "../../../../../../config/global";
import OrdersCount from "../../../share/OrdersCount.vue";

export default {
  name: "ProductsTableCount",
  components: {
    OrdersCount,
  },

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getStautsColor() {
      const status = get(this.data, "incident_price.status", "unmanaged");
      const item = incidentProgressMap(this.$i18n).find((item) =>
        item.status.includes(status)
      );
      return item ? item.color : "";
    },

    getQta() {
      return get(this.data, "qta");
    },
  },
};
</script>
