<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol col="auto">
        <CDropdown
          v-if="canBeEdit()"
          :togglerText="
            $t('role.orders.manage.incidents.details.table.actionBtn')
          "
          color="primary"
          :disabled="actionsDisabled"
        >
          <CDropdownItem
            v-for="action in actions"
            :key="action.value"
            @click="onAction(action.value)"
            >{{ action.label }}</CDropdownItem
          >
        </CDropdown>
      </CCol>
    </CRow>

    <div class="d-flex justify-content-center m-3" v-if="loading">
      <CSpinner color="info" />
    </div>

    <ejs-grid
      ref="grid"
      v-show="!loading"
      :dataSource="rows"
      :allowSelection="true"
      :selectionSettings="selectionSettings"
      @checkBoxChange="onCheckboxChange"
    >
      <e-columns>
        <e-column type="checkbox" width="40" textAlign="left"></e-column>
        <e-column
          field="id"
          headerText=""
          isPrimaryKey="{true}"
          width="50"
          :template="countTemplate"
        ></e-column>
        <e-column
          field="ppm"
          :headerText="$t('role.orders.manage.incident.table.name')"
          :template="nameTemplate"
        ></e-column>
        <e-column
          field="order"
          :headerText="$t('role.orders.manage.incident.table.order')"
          :template="ordersTemplate"
          width="200"
        ></e-column>
        <e-column
          field="status"
          :headerText="$t('role.orders.manage.incident.table.status')"
          width="200"
          :template="statusTemplate"
        ></e-column>
        <e-column
          field="price"
          :headerText="$t('role.orders.manage.incident.table.price')"
          width="100"
          :template="priceTemplate"
        ></e-column>
        <e-column
          field="actions"
          headerText=""
          width="70"
          :template="actionsTemplate"
        ></e-column>
      </e-columns>
    </ejs-grid>

    <OrdersIncidentBatchActionModal />
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import ProductsTableCount from "./ProductsTableCount";
import ProductsTableName from "./ProductsTableName";
import ProductsTableOrders from "./ProductsTableOrders";
import ProductsTableStatus from "./ProductsTableStatus";
import ProductsTablePrice from "./ProductsTablePrice";
import ProductsTableActions from "./ProductsTableActions";
import { buildPredicate } from "../../../../../../helpers/common";
import orderManagementMixin from "../../../share/mixin";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import { INCIDENT_ACTIONS_BATCH } from "../../../../../../config/global";
import OrdersIncidentBatchActionModal from "../../../share/OrdersIncidentBatchActionModal";
import { getIncidentBatchActionOptions } from "../../../../../../helpers/options";

export default {
  name: "ProductsTable",

  components: {
    OrdersIncidentBatchActionModal,
  },

  mixins: [orderManagementMixin],

  props: {
    incident: { type: Object, required: true },
  },

  watch: {
    incident() {
      this.itemSelected = Object.assign({});
      this.loadData();
    },
  },

  data: function () {
    return {
      INCIDENT_ACTIONS_BATCH,
      PERMS,
      loading: false,
      rows: [],
      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      itemSelected: {},

      actions: getIncidentBatchActionOptions(this.$i18n),
      actionsDisabled: true,

      countTemplate: () => {
        return { template: ProductsTableCount };
      },

      nameTemplate: () => {
        return { template: ProductsTableName };
      },

      ordersTemplate: () => {
        return { template: ProductsTableOrders };
      },

      statusTemplate: () => {
        return { template: ProductsTableStatus };
      },

      priceTemplate: () => {
        return { template: ProductsTablePrice };
      },

      actionsTemplate: () => {
        return { template: ProductsTableActions };
      },

      weightEntryData: null,
      weightModalShow: false,
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  mounted() {
    this.loadData();
  },

  beforeDestroy() {},

  methods: {
    loadData() {
      const roleId = this.$store.state.role.id;
      const incidentId = this.incident.id;
      const endpoint = filtersMap("incident.panel1.list.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId, incidentId]);
      const query = this.prepareQuery();

      this.loading = true;

      dm.executeQuery(query)
        .then((response) => {
          this.loading = false;
          this.rows = response.result;
        })
        .catch((response) => {
          this.loading = false;
          console.error(response);
        });
    },

    prepareQuery() {
      const { keyword } = this.$store.state.rounds.filters;

      const map = filtersMap("incident.panel1.list.filters");
      let predicate = buildPredicate(
        null,
        map,
        this.$store.state.rounds.filters
      );

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("incident.panel1.list.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    onCheckboxChange() {
      const selected = this.$refs.grid.getSelectedRecords();
      this.actionsDisabled = selected.length === 0;
    },

    onAction(action) {
      const selected = this.$refs.grid.getSelectedRecords();

      this.$store.commit("incidents/batchAction", {
        action,
        details: selected,
        show: true,
      });
    },

    canBeEdit() {
      return this.incident.can_edit;
    },
  },
};
</script>
